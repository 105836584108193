<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import Policy from '@/Components/Auth/Policy.vue';
import {
  Input,
  Button,
  Checkbox,
  Divider,
  Field,
  Label,
  ErrorMessage,
} from '@agentsoftware/spectre-ui';
import AuthPageContainerLayout from '@/Layouts/Auth/AuthPageContainerLayout.vue';
import useAuth from '@/Composables/useAuth';
import {
  Head, Link, router, usePage,
} from '@inertiajs/vue3';
import AuthBanner from '@/Components/Auth/AuthBanner.vue';

const { signInForm: form } = useAuth();
const processing = ref(false);

const props = defineProps<{
  canResetPassword?: boolean;
  status?: string;
  email?: string;
  originUrl?: string;
}>();

onBeforeMount(() => {
  form.email = props.email || '';
});

const isProduction = computed(() : boolean => usePage().props.environment === 'production');

const submit = () => {
  form.post(route('login'), {
    onStart: () => {
      processing.value = true;
    },
    onError: () => {
      processing.value = false;
      form.reset('password');
    },
  });
};

</script>

<template>
  <Head title="Login" />
  <AuthPageContainerLayout>
    <div class="flex w-full flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none xl:w-1/3">
      <div class="mx-auto w-full max-w-sm">
        <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign into Spectre
        </h2>

        <form
          class="mt-10 space-y-6"
          @submit.prevent="submit"
        >
          <Field
            id="login-email-input"
            class="space-y-2"
          >
            <Label>
              Email address
            </Label>
            <div v-if="originUrl && email">
              <p class="w-full p-0 py-1.5 sm:text-sm sm:leading-6">
                {{ form.email }}
              </p>
              <a
                id="redirect-to-origin"
                :href="originUrl"
                class="w-fit cursor-pointer text-xs font-semibold text-primary-600 underline"
              >
                Use another account
              </a>
            </div>
            <Input
              v-else
              v-model="form.email"
              type="email"
              placeholder="e.g. email@example.com"
              required
              autofocus
              :invalid="!!form.errors.email"
            />
            <ErrorMessage v-if="form.errors.email">
              {{ form.errors.email }}
            </ErrorMessage>
          </Field>
          <Field
            id="login-password-input"
            class="space-y-2"
          >
            <Label>
              Password
            </Label>
            <Input
              v-model="form.password"
              type="password"
              required
              :invalid="!!form.errors.password"
            />
            <ErrorMessage v-if="form.errors.password">
              {{ form.errors.password }}
            </ErrorMessage>
          </Field>
          <div class="flex items-center justify-between">
            <Field
              id="remember-me"
              class="flex items-center space-x-3"
            >
              <Checkbox v-model="form.remember" />
              <Label>
                Remember me
              </Label>
            </Field>

            <div class="text-sm leading-6">
              <Link
                v-if="canResetPassword"
                id="forgot-password-link"
                :href="route('password.request')"
                class="font-semibold text-neutral-800 hover:underline"
              >
                Forgot password?
              </Link>
            </div>
          </div>
          <Button
            id="login-button"
            block
            :loading="processing"
          >
            Sign in
          </Button>
        </form>
        <div class="mt-4">
          <div v-if="!isProduction">
            <Divider>
              or
            </Divider>
            <div class="mt-4">
              <Button
                id="register-button"
                block
                color="secondary"
                @click="() => router.visit(route('register'))"
              >
                Register
              </Button>
            </div>
          </div>

          <Policy class="mx-auto mt-4 w-9/12" />
        </div>
      </div>
    </div>
    <AuthBanner />
  </AuthPageContainerLayout>
</template>
